<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-8">
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-7 col-sm-6">
            <form class="search-form" @submit.prevent="doSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
              </div>
            </form>
          </div>
          <div class="col-4 col-sm-3">
            <router-link class="btn btn-primary" to="/settings/channels/add">Add Channel</router-link>
          </div>
        </div>
        <p class="text-muted mt-3">Channel</p>
        <ul class="list-unstyled px-1">
          <li class="pr-1 mb-3" v-for="channel in channelList" :key="channel.id">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center justify-content-between pb-1 flex-grow border-bottom">
                <div>
                  <p class="text-body"><i :class="channel.icon + ' text-' + channel.type"></i>&nbsp;{{ upperCaseFirst(channel.channel_name) }} ({{ channel.display_name }})</p>
                  <div class="d-flex align-items-center">
                    <p class="text-muted tx-13">{{ provider(channel.provider) }}</p>
                  </div>
                </div>
                <div>
                  <button :disabled="!['whatsapp', 'instagram', 'webchat'].includes(channel.type)" class="btn btn-outline-primary btn-sm mr-2" @click="handlerChannelInfo(channel)">
                    <font-awesome icon="info"/>
                  </button>
                  <button class="btn btn-outline-primary btn-sm" @click="deleteChannel(channel._id)">
                    <font-awesome icon="times"/>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <el-empty v-if="channelList.length == 0" description="You have no channel connected to this workspace. Let's add one!">
          <router-link class="btn btn-primary" to="/settings/channels/add">Let's go!</router-link>
        </el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { upperFirst } from 'lodash';
import channelsAPI from '../../../api/channels';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'Channels',
  metaInfo: {
    title: 'Channels',
  },
  data() {
    return {
      loaderStack: 0,
      loader: null,
      channelList: [],
      search: '',
      channel_list_mapped: [
        { name: 'WhatsApp', icon: 'mdi mdi-whatsapp', value: 'whatsapp' },
        { name: 'Telegram', icon: 'mdi mdi-telegram', value: 'telegram' },
        { name: 'WebChat', icon: 'mdi mdi-message-text', value: 'webchat' },
        { name: 'Email', icon: 'mdi mdi-email', value: 'email' },
        { name: 'Instagram', icon: 'mdi mdi-instagram', value: 'instagram' },
        { name: 'SMS', icon: 'mdi mdi-cellphone-message', value: 'sms' },
        { name: 'Facebook Messenger', icon: 'mdi mdi-facebook-messenger', value: 'fb_messenger' },
      ],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.getChannelList();
  },
  methods: {
    upperCaseFirst(string) {
      return upperFirst(string);
    },
    provider(string) {
      let str = '';
      switch (string) {
        case 'makna':
          str = 'Makna';
          break;
        case 'damcorp':
          str = 'Damcorp';
          break;
        case 'client_premise':
          str = 'On premise';
          break;
        default:
          break;
      }
      return str;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    doSearch() {
      this.page = 1;
      this.getChannelList();
    },
    pageChanged(page) {
      this.page = page;
      this.getChannelList();
    },
    async getChannelList() {
      this.showLoader();
      await channelsAPI.getList(this.activeWorkspace._id, this.page, this.search)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.channelList = res.data.channels.map((v) => {
            const obj = this.channel_list_mapped.find((ch) => ch.value === v.type);
            v.channel_name = obj.name;
            v.icon = obj.icon;
            v.display_name = v.details?.display_name || '-';
            return v;
          });
        })
        .catch(() => {});
      this.hideLoader();
    },
    async deleteChannel(id) {
      this.$confirm(this.$t('channels.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await channelsAPI.delete(this.activeWorkspace._id, id)
              .catch(() => {});
            await popupErrorMessages(response);

            this.getChannelList();
            this.$message({
              message: this.$t('channels.success.delete'),
              type: 'success',
            });
            instance.confirmButtonLoading = false;
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    handlerChannelInfo(channel) {
      this.$router.push({
        name: 'Channel Profile',
        params: {
          channel_id: channel._id,
        },
      });
    },
  },
};
</script>
